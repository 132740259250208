import { route } from '@md/routing';
import { accessPermission } from '@md/users/permissions';
import {
  PAYMENTS_TRANSFERS_VIEW_LIST,
  PAYMENTS_TRANSFERS_VIEW_CREATE,
  PAYMENTS_TRANSFERS_VIEW_EDIT,
  PAYMENTS_TRANSFERS_VIEW_EXTEND_CREATE,
  PAYMENTS_TRANSFERS_VIEW_EXTEND_EDIT,
  PAYMENTS_TRANSFERS_VIEW_EXTEND_VIEW,
} from '@md/finances/accessTokens';
import { paymentTransfers } from '@md/finances/api';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Create = () => import('./views/Create');
const Edit = () => import('./views/Edit');

const TRANSFERS_PREFIX = 'cabinet:finances:transfers:';
const p = name => TRANSFERS_PREFIX + name;
export const LIST = p('list');
export const CREATE = p('create');
export const EDIT = p('edit');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission([PAYMENTS_TRANSFERS_VIEW_EXTEND_VIEW, PAYMENTS_TRANSFERS_VIEW_LIST], options),
    }),
    route('create/', Create, CREATE, {
      meta: accessPermission([PAYMENTS_TRANSFERS_VIEW_EXTEND_CREATE, PAYMENTS_TRANSFERS_VIEW_CREATE], options),
    }),

    receiveRoute(paymentTransfers.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission([PAYMENTS_TRANSFERS_VIEW_EXTEND_EDIT, PAYMENTS_TRANSFERS_VIEW_EDIT], options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),

    { path: '', redirect: { name: LIST } },
  ];
}
