export const TRANSACTIONS_VIEW_LIST = 'FINANCES_TRANSACTIONS_VIEW_LIST';
export const TRANSACTIONS_VIEW_ANY_LIST = 'FINANCES_TRANSACTIONS_VIEW_ANY_LIST';
export const TRANSACTIONS_EDIT = 'FINANCES_TRANSACTIONS_EDIT';
export const TRANSACTIONS_STATUS_EDIT = 'FINANCES_TRANSACTIONS_STATUS_EDIT';
export const TRANSACTIONS_VIEW = 'FINANCES_TRANSACTIONS_VIEW';
export const TRANSACTIONS_CREATE = 'FINANCES_TRANSACTIONS_CREATE';

export const TRANSACTIONS_BAR_VIEW_LIST = 'FINANCES_TRANSACTIONS_BAR_VIEW_LIST';

export const SALARY_VIEW_LIST = 'FINANCES_SALARY_VIEW_LIST';
export const SALARY_VIEW_ANY_LIST = 'FINANCES_SALARY_VIEW_ANY_LIST';

export const CASHBOX_CREATE = 'FINANCES_CASH_TRANSACTIONS_CREATE';
export const CASHBOX_VIEW_LIST = 'FINANCES_CASH_TRANSACTIONS_VIEW_LIST';

export const PAYMENTS_TRANSFERS_VIEW_ANY_LIST = 'PAYMENTS_TRANSFERS_VIEW_ANY_LIST';
export const PAYMENTS_TRANSFERS_VIEW_CREATE = 'PAYMENTS_TRANSFERS_VIEW_CREATE';
export const PAYMENTS_TRANSFERS_VIEW_EDIT = 'PAYMENTS_TRANSFERS_VIEW_EDIT';
export const PAYMENTS_TRANSFERS_VIEW_EDIT_STATUS = 'PAYMENTS_TRANSFERS_VIEW_EDIT_STATUS';
export const PAYMENTS_TRANSFERS_VIEW_LIST = 'PAYMENTS_TRANSFERS_VIEW_LIST';
export const PAYMENTS_TRANSFERS_VIEW_VIEW = 'PAYMENTS_TRANSFERS_VIEW_VIEW';
export const PAYMENTS_TRANSFERS_VIEW_EXTEND_CREATE = 'PAYMENTS_TRANSFERS_VIEW_EXTEND_CREATE';
export const PAYMENTS_TRANSFERS_VIEW_EXTEND_EDIT = 'PAYMENTS_TRANSFERS_VIEW_EXTEND_EDIT';
export const PAYMENTS_TRANSFERS_VIEW_EXTEND_VIEW = 'PAYMENTS_TRANSFERS_VIEW_EXTEND_VIEW';
